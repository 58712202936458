import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const SectionTitle = ({
	title,
	description,
	level,
	theme,
	type,
	className,
	showFigure
}) => {
	const header = useMemo(() => {
		switch (level) {
			case '1':
				return <h1 className={styles.titleText}>{title}</h1>;
			case 'no':
				return <div className={styles.titleText}>{title}</div>;
			default:
				return <h2 className={styles.titleText}>{title}</h2>;
		}
	}, [title, level]);

	const innerClasses = cx(
		styles.sectionInner,
		type && styles[`sectionInner_type_${type}`],
		showFigure && styles.sectionInner_hasFigure
	);

	return (
		<div className={cx(styles.sectionTitle, className)}>
			<div className={innerClasses}>
				{header}
				{description && <div className={styles.description}>{description}</div>}
				{showFigure && (
					<div className={styles.figure}>
						<div
							className={cx(
								styles.figureMain,
								theme && styles[`figureColor_${theme}`]
							)}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	level: PropTypes.oneOf(['1', 'no']),
	theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
	type: PropTypes.oneOf(['catalogPublications']),
	className: PropTypes.string,
	showFigure: PropTypes.bool
};

SectionTitle.defaultProps = {
	theme: PropTypes.oneOf(['primary']),
	showFigure: true
};

export default SectionTitle;
