import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import parse from 'html-react-parser';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

const DescriptionImage = ({ author, source }) => {
	const [showTextImage, setShowTextImage] = useState(false);
	const textImage = [];

	const toggleDescriptionText = () => setShowTextImage(!showTextImage);

	if (author) {
		textImage.push(`Автор: ${author}`);
	}

	if (source) {
		textImage.push(`Источник: ${source}`);
	}

	useEffect(() => {
		setShowTextImage(false);
	}, [author, source, setShowTextImage]);

	return textImage.length ? (
		<div className={styles.DescriptionImage}>
			<span
				className={cx(
					styles.DescriptionImage_Content,
					showTextImage && styles.DescriptionImage_Content__show
				)}
			>
				<span className={styles.DescriptionImage_Text}>
					{parse(textImage.join(', '))}
				</span>
			</span>
			<button
				title="Информация об изображении"
				type="button"
				className={cx(
					styles.DescriptionImage_Button,
					showTextImage && styles.DescriptionImage_Button__active
				)}
				onClick={toggleDescriptionText}
			>
				<Icon icon="info" className={styles.DescriptionImage_Icon} />
			</button>
		</div>
	) : null;
};

DescriptionImage.propTypes = {
	author: PropTypes.string,
	source: PropTypes.string
};

export default DescriptionImage;
