import React from 'react';
import PropTypes from 'prop-types';
import { getEntityUrl } from '@culture/common-helpers/cultureUtils';

import BaseCard from './BaseCard';

const PublicationCard = ({
	entity,
	sizeType,
	entityType,
	index,
	colorsList,
	isSlider
}) => {
	const { _id, type, title, name, thumbnailFile, color, locale } = entity;

	const entityUrl = getEntityUrl(entityType ?? type, {
		_id,
		name,
		type
	});

	return (
		<BaseCard
			title={title}
			thumbnailFile={thumbnailFile}
			url={entityUrl}
			sizeType={sizeType}
			color={color}
			colorsList={colorsList}
			index={index}
			isSlider={isSlider}
			location={locale?.title}
		/>
	);
};

PublicationCard.propTypes = {
	entity: PropTypes.shape({
		_id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		thumbnailFile: PropTypes.object,
		type: PropTypes.string,
		name: PropTypes.string.isRequired,
		color: PropTypes.string,
		locale: PropTypes.object
	}),
	sizeType: PropTypes.string,
	entityType: PropTypes.string,
	index: PropTypes.number,
	colorsList: PropTypes.arrayOf(PropTypes.string),
	isSlider: PropTypes.bool
};

PublicationCard.defaultProps = {
	index: null,
	colorsList: [
		'tertiary',
		'quaternary',
		'secondary',
		'secondary',
		'tertiary',
		'quaternary'
	]
};

export default PublicationCard;
