import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

const Line = ({ type, className }) => {
	switch (type) {
		case 'hr':
			return <hr className={cx(styles.line, className)} />;
		default:
			return <div className={cx(styles.line, className)} />;
	}
};

Line.propTypes = {
	type: PropTypes.oneOf(['hr']),
	className: PropTypes.string
};

export default Line;
