import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import NextLink from 'components/NextLink';

import styles from './styles.module.scss';

const Button = ({ title, url, target, onClick, gutter, className }) => {
	const classes = cx(
		styles.button,
		gutter && styles[`gutter_${gutter}`],
		className
	);

	return url ? (
		<NextLink className={classes} href={url} next={!target} target={target}>
			{title}
		</NextLink>
	) : (
		<button className={classes} type="button" onClick={onClick}>
			{title}
		</button>
	);
};

Button.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	target: PropTypes.oneOf(['_blank']),
	onClick: PropTypes.func,
	gutter: PropTypes.oneOf(['large']),
	className: PropTypes.string
};

export default Button;
