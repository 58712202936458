import _ from 'underscore';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import NextLink from 'components/NextLink';
import Thumbnail from 'components/Thumbnail';

import { getColorForCard } from 'utils/helpers/endlessColorsList';

import { FilePropType } from 'propTypes/common';

import styles from './styles.module.scss';

const PublicationCard = ({
	title,
	thumbnailFile,
	target,
	url,
	sizeType,
	color,
	colorsList,
	index,
	isSlider,
	tag,
	date,
	location
}) => {
	const [thumbnailHeightVar, setThumbnailHeightVar] = useState(0);
	const refThumbnail = useRef(null);
	const getColor = getColorForCard(colorsList);

	const isBBcodeGroupElement = !_.isNull(index);
	const backgroundColor = isBBcodeGroupElement ? getColor(index) : color;
	const smallSizeClass = styles[`sizeType_${sizeType}`];

	useEffect(() => {
		setThumbnailHeightVar(refThumbnail?.current?.offsetHeight);

		const thumbnailResizeObserver = new ResizeObserver(() => {
			setThumbnailHeightVar(refThumbnail?.current?.offsetHeight);
		});

		thumbnailResizeObserver.observe(refThumbnail?.current);

		return () => {
			thumbnailResizeObserver.disconnect();
		};
	}, [refThumbnail]);

	return (
		<NextLink
			className={cx(
				styles.mainContainer,
				smallSizeClass,
				isSlider && styles.isSlider,
				tag && styles.hasTag
			)}
			href={url}
			next={!target}
			target={target}
		>
			<div
				className={cx(
					styles.contentWrapper,
					styles[`color_${backgroundColor}`]
				)}
			>
				<div ref={refThumbnail}>
					<Thumbnail
						file={thumbnailFile}
						params={{ width: 360, height: 270 }}
						className={styles.image}
						keepRatio
					/>
				</div>
				<div
					className={styles.content}
					style={{ '--card-image-height': `${thumbnailHeightVar}px` }}
				>
					<div className={styles.title}>{title}</div>
					{location && <div className={styles.location}>{location}</div>}
					{date && <div className={styles.startDate}>{date}</div>}
				</div>
			</div>
			{tag && (
				<div className={styles.tag}>
					<div className={styles.tagInner}>{tag}</div>
				</div>
			)}
			<div
				className={styles.decorativeBorder}
				style={{ '--border-left-height': `${thumbnailHeightVar - 20}px` }}
			>
				<div className={styles.decorativeBorderInner} />
			</div>
		</NextLink>
	);
};

PublicationCard.propTypes = {
	title: PropTypes.string.isRequired,
	thumbnailFile: FilePropType,
	url: PropTypes.string,
	sizeType: PropTypes.string,
	color: PropTypes.string,
	colorsList: PropTypes.arrayOf(PropTypes.string),
	index: PropTypes.number,
	isSlider: PropTypes.bool,
	tag: PropTypes.string,
	date: PropTypes.string,
	target: PropTypes.oneOf(['_blank']),
	location: PropTypes.string
};

PublicationCard.defaultProps = {
	index: null
};

export default PublicationCard;
