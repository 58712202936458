import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const NextLink = ({ href, className, children, linkProps, next, ...props }) =>
	!href || next ? (
		<Link href={href || ''} {...linkProps}>
			<a className={className} {...props}>
				{children}
			</a>
		</Link>
	) : (
		<a href={href} className={className} {...props}>
			{children}
		</a>
	);

NextLink.propTypes = {
	href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	next: PropTypes.bool,
	className: PropTypes.string,
	linkProps: PropTypes.object,
	children: PropTypes.node
};

NextLink.defaultProps = {
	next: false,
	linkProps: {}
};

export default NextLink;
