export const getColorForCard = (colorsList, reverse) => {
	const colors = reverse
		? colorsList.concat([...colorsList].reverse())
		: colorsList;

	return (index) => {
		if (index >= colors.length) {
			return colors[index % colors.length];
		}
		return colors[index];
	};
};
