import _ from 'underscore';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getImageUrl } from '@culture/common-helpers/cultureUtils';

import { ImageFormatContext } from 'contexts/ImageFormatContext';
import DescriptionImage from 'components/DescriptionImage';
import LazyLoadImage from 'components/LazyLoad/image';

import styles from './styles.module.scss';

const Thumbnail = ({
	file,
	params,
	alt,
	className,
	keepRatio,
	preloadImage,
	behavior,
	theme,
	showMeta
}) => {
	const imageFormat = useContext(ImageFormatContext);

	const thumbnailCx = cx(
		styles.thumbnail,
		{
			[styles.keepRatio]: keepRatio,
			[styles[`theme_${theme}`]]: theme
		},
		styles[behavior],
		className
	);

	const ratio = (params.height / params.width) * 100;
	let imageSrc;

	if (_(file).isObject()) {
		imageSrc = getImageUrl(file, {
			format: imageFormat,
			params: {
				crop: 'fill',
				gravity: 'center',
				...params
			}
		});
	} else {
		imageSrc = file;
	}

	return imageSrc ? (
		<div
			className={thumbnailCx}
			style={{ paddingBottom: keepRatio ? `${ratio}%` : null }}
		>
			<LazyLoadImage
				preloadImage={preloadImage}
				imageSrc={imageSrc}
				className={styles.imgWrapper}
			>
				<img className={styles.img} src={imageSrc} alt={alt || ''} />
			</LazyLoadImage>
			{showMeta && (
				<DescriptionImage
					author={file?.meta?.author}
					source={file?.meta?.source}
				/>
			)}
		</div>
	) : null;
};

Thumbnail.propTypes = {
	preloadImage: PropTypes.bool,
	file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	params: PropTypes.object,
	alt: PropTypes.string,
	keepRatio: PropTypes.bool,
	className: PropTypes.string,
	behavior: PropTypes.oneOf(['background', 'image']),
	theme: PropTypes.oneOf(['border']),
	showMeta: PropTypes.bool
};

Thumbnail.defaultProps = {
	preloadImage: false,
	params: {},
	keepRatio: false,
	behavior: 'background'
};

export default Thumbnail;
